@import './color';

* {
  &:active, :focus {
    outline: none !important;
  }
}
label {
  margin-bottom: 0;
}
a:not(.mat-button):not(.mat-raised-button):not(.mat-fab):not(.mat-mini-fab):not([mat-list-item]) {
  color: $color-primary-blue;
}