.chat-toolbar {
  height: 42px !important;
  background-color: #333;
  color: #fff;
}
.chat-container {
  position: absolute !important;
  top: 110px;
  bottom: 0;
  left: 0;
  right: 0;
}

.chat-sidenav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  // background: rgba(255, 0, 0, 0.5);
}

.chat-sidenav-toolbar {
  font-size: 14px;

  .btn-group {
    .btn, .dropdown-item {
      font-size: 14px;
    }
  }

  .search-form-group {
    margin: 0 0 0 auto;

    &.full-width {
      width: 100%;
    }
  }

  .pause-bot {
    display: flex;
    margin-left: auto;
    padding-top: 10px;

    .pause-bot-option {
      margin-left: 10px;
    }

    .custom-control-label {
      line-height: 1.8;
    }
  }
}

.chat-session-list {
  .no-session {
    text-align: center;
    color: #ccc;
  }
  .loading-panel {
    text-align: center;
    color: $color-primary-blue;
  }
  .list-group-item {
    min-height: 90px;
    &.flex-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-1 {
      -webkit-box-flex: 1 !important;
      flex: 1 !important;
    }
    .w-min-0 {
      min-width: 0px;
    }
    .text-muted {
      color: #adb5bd !important;
    }
    .text-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    small, .small {
      font-size: 80%;
      font-weight: 400;
    }
    &.active {
      color: #fff !important;
    }
  }
}

.emojione {
  display: inline-block;
  height: 1em !important;
  margin: 0 0.075em;
}
.avatars {
  display: inline-block;
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  overflow: hidden;
  vertical-align: middle;
  &.avatars-sm {
    width: 3rem;
    height: 3rem;
  }
}
.avatars-one {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;

  &:nth-child(1) {
    left: 0;
    top: 0;
  }
}

.chat-sidenav-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  border-right: 1px solid #333;
  // background: rgba(255, 0, 0, 0.5);
}

.chat-main-box {
  display: flex !important;
  flex-direction: column;

  .chat-box-holder {
    display: flex !important;
    flex: 1;

    .scrollable-container {
      display: flex !important;
      flex: 1;

      .layout {
        width: 100%;
      }

      .chat-box {
        height: 100%;
        .header {
          display: none;
        }
        .scrollable {
          max-height: calc(100vh - 250px);
        }
      }
    }
  }
}