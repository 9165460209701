@import './color';

.mat-dialog-title {
  margin-bottom: 10px;
  color: $color-primary-blue;

  &.-success {
    color: #67C23A;
  }

  &.-danger {
    color: #F56C6C;
  }
  &.-warning {
    color: #e0a800;
  }
}
.msq-dialog-actions {
  justify-content: flex-end;
  .dialog-action-button {
    margin: 5px;
    min-width: 80px;
  }
}