@import './color';
@import './fonts';

.admin-page {
  padding-top: 20px;
  padding-bottom: 20px;

  .header-title {
    color: $color-primary-blue;
    font-style: italic;

    .status {
      color: $color-dark-grey;
    }
  }
  .line-user-picture {
    width: 60px;
    height: 60px;
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #efefef;
    margin-right: 10px;
    border-radius: 50%;
  }

  .hcp-table {
    .label-col {
      width: 250px;
    }
  }

  .hcp-doc-no-title {
    color: $color-primary-blue;
    font-style: italic;
    margin-right: 10px;
  }

  .section-title {
    // font-family: $font-minion;
    font-family: $font-roche-sans;
  }
}
