@import './color';

.hcp-page {
  padding-top: 20px;
  border-top: 1px solid $color-primary-grey;
  height: 100%;

  .page-header {
    margin-bottom: 30px;

    .back-button {
      margin-bottom: 24px;
      height: 40px;
      display: flex;
      align-items: center;
      color: #000;
      font-weight: bold;
      text-decoration: none;
    }

    .roche-icon-holder {
      margin-bottom: 15px;
      .roche-icon {
        display: block;
      }
    }

    .page-title {
      color: $color-primary-blue;
      font-style: italic;
      line-height: 1;
    }
  }
  .instruction {
    font-size: .9em;
    color: $color-dark-grey;
    font-style: italic;
  }
  .hcp-form {
    margin-bottom: 80px;

    label {
      font-weight: bold;
    }

    .custom-radio {
      label {
        font-weight: normal;
      }
    }
    .legal-section {
      label {
        font-weight: normal;
        color: $color-dark-grey;
        font-style: italic;
      }
    }
  }
  // iOS 11.0-11.2
  @supports (margin-bottom: constant(safe-area-inset-bottom)) {
    .hcp-form {
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      margin-bottom: calc(80px + var(--safe-area-inset-bottom));
    }
  }
  // iOS 11.2+
  @supports (margin-bottom: env(safe-area-inset-bottom)) {
    .hcp-form {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      margin-bottom: calc(80px + var(--safe-area-inset-bottom));
    }
  }
  .expert-list {
    padding-left: 15px;
    .expert-item {
      padding-left: 15px;
    }
    .expert-img {
      margin-bottom: 10px;
      img {
        width: 80px;
      }
    }
  }
  .section {
    .section-title {
      color: $color-primary-blue;
    }
  }
  .footer-action-buttons {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;

    background: rgba(255, 255, 255, 0.5);
  }
  // iOS 11.0-11.2
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
    .footer-action-buttons {
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      padding-bottom: calc(10px + var(--safe-area-inset-bottom));
    }
  }
  // iOS 11.2+
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    .footer-action-buttons {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      padding-bottom: calc(10px + var(--safe-area-inset-bottom));
    }
  }

  .page-detail {
    .table {
      .label-col {
        width: 50%;
      }
    }
    .hcp-verifying-alert {
      .icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}

app-pdf-viewer {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.pdf-viewer {
  display: block;
  height: 100vh;
}
.availability-result-list {
  .list-group-item {
    color: $color-dark-grey !important;
    display: flex;
    align-items: center;
    &:hover, &:active {
      text-decoration: none;
    }
  }
  .cvp-info {
    font-size: .8em;
    color: $color-primary-blue;
  }
  .icon {
    float: right;
    color: #0066cc;
    font-size: 1.5em;
    margin-right: 0;
    margin-left: auto;
  }
}
.sales-rep-result-list {
  .list-group-item {
    color: $color-dark-grey !important;
    display: flex;
    align-items: center;
    &:hover, &:active {
      text-decoration: none;
    }
  }
  .cvp-info {
    font-size: .8em;
    color: $color-primary-blue;
  }
  .icon {
    color: #0066cc;
    font-size: 1.5em;
    margin-right: 0;
    margin-left: auto;
  }
}

.contact-menu-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 0;

  .contact-menu-item {
    display: flex;
    height: 48px;
    border-radius: 4px;
    border: 2px solid $color-primary-blue;
    padding: 12px 16px;
    width: 100%;
    justify-content: stretch;
    align-items: center;
    margin-bottom: 16px;

    a {
      display: flex;
      width: 100%;
      justify-content: stretch;
      align-items: center;
      text-decoration: none;
    }

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__icon {
      margin-right: 10px;
      position: relative;
      top: 4px;
    }

    &__arrow {
      justify-content: flex-end;
      margin-left: auto;
      align-items: center;
    }
  }
}

.contact-list {
  .contact-item {
    &-team {
      margin-bottom: 16px;
      &__name {
        // font-family: $font-imago;
        font-family: $font-roche-sans;
        font-size: 28px;
        font-weight: bold;
      }
    }

    &-topic {
      margin-bottom: 16px;
      &__name {
        // font-family: $font-imago;
        font-family: $font-roche-sans;
        font-size: 24px;
        font-weight: bold;
      }
    }

    &-subtopic {
      margin-bottom: 16px;
      &__name {
        // font-family: $font-imago;
        font-family: $font-roche-sans;
        font-size: 18px;
        font-weight: bold;
      }
    }


    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    border: 1px solid #888;

    &__row {
      display: flex;
      justify-content: stretch;
      border-bottom: 1px solid #888;

      &:last-child {
        border-bottom: none;
      }
    }

    &__label {
      padding: 4px;
      min-width: 80px;
      border-right: 1px solid #888;
      font-weight: bold;
    }

    &__value {
      padding: 4px;
    }
  }
}

.faq-accordion {
  .mat-expansion-panel {
    border-bottom: 1px solid $color-primary-blue;
    border-left: 2px solid $color-primary-blue;
    border-right: 2px solid $color-primary-blue;

    &:first-of-type {
      border-top: 2px solid $color-primary-blue;
    }
    &:last-of-type {
      border-bottom: 2px solid $color-primary-blue;
    }
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel-header {
    .mat-expansion-panel-header-title {
      display: flex;
      align-items: center;
      line-height: 1.5;

      .mat-icon {
        color: $color-primary-blue;
        margin-right: 8px;
      }
    }
  }
}
.faq-action-button {
  display: flex;
  font-size: 14px;
  align-items: center;

  &__label {
    display: flex;
    align-items: center;

    .mat-icon {
      margin-right: 8px;
    }
  }

  &__icon {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.img-responsive {
  max-width: 100%;
  height: auto;
}
