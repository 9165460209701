@import './color';

.mat-snack-bar-container {
  &.error-snackbar {
    // background-color: #F56C6C;
    background-color: $color-secondary-red;
  }
  &.success-snackbar {
    // background-color: #67C23A;
    background-color: $color-secondary-green;
  }
  .mat-simple-snackbar {
    color: #fff;
  }
  .mat-button {
    color: #fff;
  }
}

.mat-error {
  font-size: 12px;
}

.cdk-global-overlay-wrapper {
  // iOS 11.0-11.2
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
    .footer-action-buttons {
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      padding-bottom: calc(var(--safe-area-inset-bottom));
    }
  }
  // iOS 11.2+
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    .footer-action-buttons {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      padding-bottom: calc(var(--safe-area-inset-bottom));
    }
  }
}

.mat-table {
  width: 100%;

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 10px;
  }

  td {
    padding: 10px;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding: 10px;
  }

  &.table-sm {
    tr.mat-header-row {
      height: 36px;
    }
  }

  th.mat-header-cell {
    background: $color-primary-grey;
    color: $color-dark-grey;
  }

  .index-col {
    width: 100px;
    text-align: center;
  }

  .email-col {
    width: 250px;
    text-align: left;
  }

  .doc-no-col {
    width: 120px;
    text-align: center;
  }

  .action-col {
    width: 200px;
    text-align: center;

    &.-small {
      width: 100px;
    }
  }

  .status-col {
    width: 120px;
    text-align: center;
  }

  .line-user-col {
    width: 150px;
    code {
      font-size: 12px;
      line-height: 1;
    }
  }

  .contact-col {
    width: 200px;
  }
}
.no-data-row {
  border: 1px solid #dee2e6;
  padding: 10px;
  text-align: center;
}