// $font-imago: 'imago-pro-roche-n4', 'imago-pro-roche', 'Sarabun', 'arial', 'sans-serif';
// $font-minion: 'minion-pro', 'Sarabun', 'times', 'serif';

@font-face {
  font-family: 'Roche Sans';
  src: url('assets/fonts/RocheSansMedium-BoldItalic.eot');
  src: url('assets/fonts/RocheSansMedium-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/RocheSansMedium-BoldItalic.woff2') format('woff2'),
      url('assets/fonts/RocheSansMedium-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roche Sans';
  src: url('assets/fonts/RocheSans-Italic.eot');
  src: url('assets/fonts/RocheSans-Italic.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/RocheSans-Italic.woff2') format('woff2'),
      url('assets/fonts/RocheSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roche Sans';
  src: url('assets/fonts/RocheSans-Regular.eot');
  src: url('assets/fonts/RocheSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/RocheSans-Regular.woff2') format('woff2'),
      url('assets/fonts/RocheSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roche Sans';
  src: url('assets/fonts/RocheSans-Bold.eot');
  src: url('assets/fonts/RocheSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/RocheSans-Bold.woff2') format('woff2'),
      url('assets/fonts/RocheSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$font-roche-sans: 'Roche Sans', 'Sarabun', 'arial', 'sans-serif';
