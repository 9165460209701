.icon-in-detail:before {
  content: "\e902"
}

.icon-xing:before {
  content: "\e61e"
}

.icon-triangle-product.icon-after:after,.icon-triangle-product:before {
  content: "\e900";
  font-size: .75em
}

.icon-chat:before {
  content: "\e901"
}

.icon-close_button:before {
  content: "\e04a"
}

.icon-tip-2:before {
  content: "\e049"
}

.icon-rss:before {
  content: "\e048"
}

.icon-reset:before {
  content: "\e046"
}

.icon-additional-indication:before {
  content: "\e045"
}

.icon-arrow::before,.icon-internal.icon-after::after,.icon-internal::before {
  content: "\e044"
}

.icon-arrow-back::before,.icon-internal-back::before {
  content: "\e043"
}

.icon-arrow-light:before {
  content: "\e042"
}

.icon-arrow-light-back:before {
  content: "\e041"
}

.icon-attachment:before {
  content: "\e040"
}

.icon-award:before {
  content: "\e03f"
}

.icon-bookmark:before {
  content: "\e03e"
}

.icon-bullet:before {
  content: "\e03d"
}

.icon-cart:before {
  content: "\e03c"
}

.icon-checked:before {
  content: "\e03b"
}

.icon-circle:before {
  content: "\e620"
}

.icon-close-light:before {
  content: "\e603"
}

.icon-close:before {
  content: "\e03a"
}

.icon-comment:before {
  content: "\e039"
}

.icon-date:before {
  content: "\e038"
}

.icon-directions:before {
  content: "\e036"
}

.icon-download.icon-after:after,.icon-download:before {
  content: "\e035"
}

.icon-edit:before {
  content: "\e034"
}

.icon-fax:before {
  content: "\e033"
}

.icon-file:before {
  content: "\e032"
}

.icon-filter:before {
  content: "\e031"
}

.icon-forward:before {
  content: "\e030"
}

.icon-full-view:before {
  content: "\e02f"
}

.icon-home:before {
  content: "\e02e"
}

.icon-image:before {
  content: "\e02d"
}

.icon-slideshow:before {
  content: "\e600"
}

.icon-movie:before {
  content: "\e026"
}

.icon-letter:before {
  content: "\e02c"
}

.icon-location:before {
  content: "\e02b"
}

.icon-magazine:before {
  content: "\e02a"
}

.icon-minus:before {
  content: "\e029"
}

.icon-minutes:before {
  content: "\e622"
}

.icon-mobile:before {
  content: "\e028"
}

.icon-molecular-entity:before {
  content: "\e027"
}

.icon-newwindow.icon-after::after,.icon-newwindow:before {
  content: "\e025"
}

.icon-options:before {
  content: "\e024"
}

.icon-orientation:before {
  content: "\e023"
}

.icon-overview:before {
  content: "\e022"
}

.icon-path:before {
  content: "\e021"
}

.icon-pause:before {
  content: "\e020"
}

.icon-pause-circle:before {
  content: "\e903"
}

.icon-pdf:before {
  content: "\e61f"
}

.icon-phone:before {
  content: "\e01e"
}

.icon-play:before {
  content: "\e01d"
}

.icon-play-circle:before {
  content: "\e904"
}

.icon-plus:before {
  content: "\e01b"
}

.icon-podcast:before {
  content: "\e01a"
}

.icon-print:before {
  content: "\e019"
}

.icon-rate:before {
  content: "\e018"
}

.icon-reload:before {
  content: "\e017"
}

.icon-retweet:before {
  content: "\e60d"
}

.icon-roche-logo:before {
  content: "\e616"
}

.icon-search:before,.icon-zoom:before {
  content: "\e013"
}

.icon-share:before {
  content: "\e012"
}

.icon-sm-facebook:before {
  content: "\e011"
}

.icon-sm-gplus:before {
  content: "\e010"
}

.icon-sm-linkedin:before {
  content: "\e00f"
}

.icon-sm-twitter:before {
  content: "\e00e"
}

.icon-sm-youtube:before {
  content: "\e00d"
}

.icon-sm-pinterest:before {
  content: "\e04d"
}

.icon-sort-down.icon-after:after,.icon-sort-down:before {
  content: "\e00c"
}

.icon-sort-up.icon-after:after,.icon-sort-up:before {
  content: "\e00b"
}

.icon-sound:before {
  content: "\e00a"
}

.icon-soundoff:before {
  content: "\e009"
}

.icon-stop:before {
  content: "\e008"
}

.icon-tag:before {
  content: "\e007"
}

.icon-thumb:before {
  content: "\e006"
}

.icon-time:before {
  content: "\e005"
}

.icon-tip:before {
  content: "\e004"
}

.icon-tooltip:before {
  content: "\e621"
}

.icon-trash:before {
  content: "\e003"
}

.icon-back:before,.icon-tweet-reply:before {
  content: "\e60c"
}

.icon-up:before {
  content: "\e002"
}

.icon-user:before {
  content: "\e001"
}

.icon-worldmap:before {
  content: "\e000"
}

.icon-pointer:before {
  content: "\e04b"
}

.icon-information:before {
  content: "\e04c"
}

.icon-arrow-pharmapipe:before {
  content: "\e04e"
}

.icon-arrow-pharmapipe-up:before {
  content: "\e04f"
}

.icon-contact-user:before {
  content: "\e051"
}

.icon-contact-letter:before {
  content: "\e052"
}

.icon-contact-phone:before {
  content: "\e053"
}

.icon-arrow-down:before {
  content: "\e054"
}

.icon-quote:before {
  content: "\e056"
}

.icon-arrow-down-thin:before {
  content: "\e609"
}

.icon-navigation-pull:before {
  content: "\e055"
}

.icon-innovation:before {
  content: "\e049"
}

.icon-hotspot:before {
  content: "\e604"
}

.icon-door-open:before {
  content: "\e605"
}

.icon-door-closed:before {
  content: "\e606"
}

.icon-door-closed-2:before {
  content: "\e608"
}

.icon-360:before {
  content: "\e607"
}

.icon-square:before {
  content: "\e008"
}

.icon-calendar:before {
  content: "\e60a"
}

.icon-hide:before {
  content: "\e60f"
}

.icon-click:before {
  content: "\e615"
}

.icon-progress-1:before {
  content: "\e618"
}

.icon-progress-2:before {
  content: "\e619"
}

.icon-progress-3:before {
  content: "\e61a"
}

.icon-field-of-study:before {
  content: "\e61b"
}

.icon-opportunities:before {
  content: "\e61c"
}

.icon-degrees:before {
  content: "\e61d"
}

@font-face {
  font-family: RocheIconFont;
  src: url(assets/fonts/RocheIconFont.woff) format("woff"),url(assets/fonts/RocheIconFont.ttf) format("truetype"),url(assets/fonts/RocheIconFont.svg#RocheIconFont) format("svg");
  font-weight: 400;
  font-style: normal;
}

[data-icon]:before {
  font-family: RocheIconFont;
  content: attr(data-icon);
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased
}

[class*=icon-]:after,[class*=icon-]:before {
  font-family: RocheIconFont;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-size: 1em;
  margin-right: 5px;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto
}

[class*=icon-]:after {
  content: none
}

[class*=icon-].icon-after:before {
  content: none
}

[class*=icon-].icon-after:after {
  margin-left: 5px;
  margin-right: 0
}

[class*=icon-]:before,[class*=icon-]:hover:before,[data-icon]:before,[data-icon]:hover:before {
  text-decoration: underline
}

[class*=icon-]:before,[class*=icon-]:hover:before,[data-icon]:before,[data-icon]:hover:before {
  text-decoration: none
}