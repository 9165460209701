@import './color';

.btn-primary {
  background-color: $color-primary-blue;
  border-color: $color-primary-blue;
}

.dropzone {
  border: 1px dashed #8f9bb3;
  background: #DFE3ED;
  text-align: center;
  color: #8f9bb3;
  border-radius: 4px;

  .dz-button {
    border: 0;
    opacity: 0;
  }

  .icon {
    pointer-events: none;
  }

  h3 {
    color: #8f9bb3;
  }

  .icon {
    font-size: 10em;
    height: auto;
    width: auto;
  }

  .dz-preview {
    display: none;
  }

  .upload-progress {
    .mat-progress-spinner {
      margin: 10px auto;
    }
  }

  .drop-message {
    display: none;
  }

  .dropzone-message {
    pointer-events: none;
  }

  &.dz-drag-hover {
    border: 2px solid #67C23A;
    background: rgb(225, 243, 216);
    color: #67C23A;

    h3 {
      color: #67c23a;
    }

    .no-upload {
      display: none;
    }
    .drop-message {
      display: block;
    }
  }
}
.badge-preview {
  text-align: center;
  .img-holder {
    background: #efefef;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;

    img {
      max-width: 100%;
    }
  }
}

.profession-radio {
  margin-bottom: 10px;
  .custom-control-label::before,
  .custom-control-label::after {
    // top: .8rem;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 16px;
  }
}
