$color-primary-blue: #0066cc;
$color-primary-grey: #b1b3b3;

$color-secondary-red : #e40046;
$color-secondary-violet: #a05eb5;
$color-secondary-green: #00965e;
$color-secondary-yellow: #ffc72c;
$color-secondary-orange: #ed8b00;
$color-secondary-cyan: #00e5ef;

$color-dark-grey: #333;
$color-grey: #b1b3b3;

// Material Patlet
@import '~@angular/material/theming';


$mat-primary-blue: (
  50: #e3f2ff,
  100: #badfff,
  200: #8ecbff,
  300: #5eb7ff,
  400: #35a7ff,
  500: #0098ff,
  600: #008af1,
  700: #0077de,
  800: #0066cc,
  900: #0047ad,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-accent-red: (
  50: #fde3e8,
  100: #fab7c6,
  200: #f689a0,
  300: #f0597b,
  400: #ea3360,
  500: #e40046,
  600: #d40045,
  700: #c00042,
  800: #ac0040,
  900: #8a003c,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
