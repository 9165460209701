@import 'themes';
@import '~@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
};

@import './partials/reset';
@import './partials/theme';
@import './partials/fonts';
@import './partials/icons';
@import './partials/color';
@import './partials/material';
@import '~bootstrap/scss/bootstrap';
@import './partials/form';
@import './partials/dialog';
@import './partials/button';
// @import '~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';

html {
  height: 100%;
}
body {
  // font-family: $font-imago;
  font-family: $font-roche-sans;
  line-height: 1.5;
  height: 100%;
  color: $color-dark-grey;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  // iOS 11.0-11.2
  @supports (height: constant(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    height: calc(100% + var(--safe-area-inset-bottom));
  }
  // iOS 11.2+
  @supports (height: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    height: calc(100% + var(--safe-area-inset-bottom));
  }
}

h1, h2 {
  // font-family: $font-minion;
  font-family: $font-roche-sans;
}
h3, h4, h5, h6 { font-weight: 500; }

a {
  color: $color-primary-blue;
}

.app-container {
  display: flex;
  width: 100%;
  height: 100%;
}

#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: $color-dark-grey;
  color: #fff;
  transition: all 0.3s;
  overflow-y: auto;
  overflow-x: hidden;

  &.active {
    margin-left: -250px;
  }

  .sidebar-header {
    padding: 13px 10px 0 15px;
    box-shadow: 4px 0 4px 1px rgba(0,0,0,.2);
    min-height: 44px;
    h3 {
      font-size: 1em;
      font-weight: 500;
    }
  }

  .sidebar-menu {
    .menu-item {
      list-style: none;
      margin: 0;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 1rem;

      &.last-item {
        border-top: 1px solid #677694;
      }
    }

    .menu-link {
      color: #fff;
      text-decoration: none;
      padding: 15px 20px;
      width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: color .4s ease-out;

      &:hover, &:active {
        // font-weight: 500;
        background-color: rgba(250, 250, 250, .1);
      }

      &.link-active {
        background-color: rgba(250,250,250,.2);
        font-weight: 500;
      }
    }

    .section {
      border-top: 1px solid #677694;
      .section-title {
        text-decoration: none;
        text-transform: uppercase;
        color: #677694;
        font-weight: 500;
        font-size: .8rem;
        line-height: .8rem;
        letter-spacing: .06rem;
        transition: padding 0s linear .4s;
        cursor: pointer;
        display: flex;
        padding: 25px 20px 15px;

        &:after {
          margin-left: auto;
        }
      }

      .submenu {
        .menu-link {
          padding-left: 40px;
        }
        &.-filter-options {
          .menu-link {
            padding-left: 60px;
            &.link-active {
              background-color: rgba(250, 250, 250, 0.1);
            }
          }
        }
      }
    }
  }
}

#content {
  width: calc(100% - 250px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;

  &.active {
    width: 100%;
  }
}

#global-logo {
  padding-top: 10px;
  margin-top: -2px;
  margin-right: -1px;
  margin-left: auto;

  &:before {
    content: "\e616";
    font-family: RocheIconFont;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
    font-size: 24px;
    color: #06c;
  }

  @media screen and (min-width: 768px) {
    display: block;
    margin: 3px -1px 0 auto;
    padding-top: 15px;
    &:before {
      font-size: 33px;
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 5px;
    &:before {
      font-size: 39px;
    }
  }
}

#app-header {
  position: relative;
  border-bottom: 1px solid #ccc;
  z-index: 45;
  min-height: 44px;
  background: #fff;

  .navbar-brand {
    font-weight: 500;
  }
}

// AUTH
.auth-form {
  padding-top: 40px;

  .form-title {
    color: $color-primary-blue;
    font-weight: bold;
    font-style: italic;
  }
}

.required {
  color: red;
  font-style: normal;
}

.roche-icon {
  margin-left: auto;
  font-style: normal;

  &:before {
    content: "\e616";
    font-family: RocheIconFont;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
    font-size: 24px;
    color: #06c;
  }

  @media screen and (min-width: 768px) {
    display: block;
    &:before {
      font-size: 33px;
    }
  }

  @media screen and (min-width: 1024px) {
    &:before {
      font-size: 39px;
    }
  }
}
.cover-container {
  max-width: 42em;
  margin-top: 100px;

  .cover-heading {
    color: $color-primary-blue;
    font-style: italic;
  }
}

@import './partials/hcp';
@import './partials/admin';
@import './partials/chat';
